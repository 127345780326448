var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "section-content" },
      [
        _c(
          "div",
          { staticClass: "list-container" },
          [
            _c(
              "b-card",
              { staticClass: "card-text shadow-sm bg-white rounded" },
              [
                _c("b-card-title", { staticClass: "card-title" }, [
                  _vm._v(" Dados gerais "),
                ]),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticStyle: {
                          "padding-left": "50px",
                          "padding-top": "25px",
                        },
                        attrs: { cols: "2" },
                      },
                      [
                        _c("b-card-text", {
                          staticClass: "card-style",
                          domProps: { textContent: _vm._s(_vm.general.name) },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticStyle: { "padding-top": "25px" } },
                      [
                        _c("b-card-text", {
                          domProps: { textContent: _vm._s(_vm.userName) },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticStyle: {
                          "padding-left": "50px",
                          "padding-top": "15px",
                        },
                        attrs: { cols: "2" },
                      },
                      [
                        _c("b-card-text", {
                          staticClass: "card-style",
                          domProps: { textContent: _vm._s(_vm.general.email) },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticStyle: { "padding-top": "15px" } },
                      [
                        _c("b-card-text", {
                          domProps: { textContent: _vm._s(_vm.login) },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.canShowCorporate,
                            expression: "!canShowCorporate",
                          },
                        ],
                        attrs: { cols: "2" },
                      },
                      [
                        _c(
                          "b-card-text",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.canShowCorporate,
                                expression: "!canShowCorporate",
                              },
                            ],
                          },
                          [
                            !_vm.canShowCorporate
                              ? _c("strong", [_vm._v("Corporação ")])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    !_vm.canShowCorporate
                      ? _c(
                          "b-col",
                          [
                            _c("b-card-text", {
                              domProps: {
                                textContent: _vm._s(_vm.corporateName),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.canShowCorporate
                  ? _c(
                      "b-button",
                      {
                        staticClass: "corporate-button",
                        on: {
                          click: function ($event) {
                            return _vm.onCreateCorporate()
                          },
                        },
                      },
                      [_vm._v(" Criar conta corporativa ")]
                    )
                  : _vm._e(),
                !_vm.canShowCorporate
                  ? _c(
                      "b-button",
                      {
                        staticClass: "corporate-button",
                        on: {
                          click: function ($event) {
                            return _vm.onEditCorporate()
                          },
                        },
                      },
                      [_vm._v(" Editar conta ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "b-card",
              { staticClass: "card-text shadow-sm bg-white rounded" },
              [
                _c("b-card-title", { staticClass: "card-title" }, [
                  _vm._v(" Alteração de senha "),
                ]),
                _c(
                  "b-row",
                  { staticStyle: { "max-width": "500px" } },
                  [
                    _c(
                      "b-col",
                      [
                        _vm.email
                          ? _c("PasswordVerificationForm", {
                              attrs: {
                                showTextVersion: false,
                                userLogin: _vm.email,
                                redirectLoginPage: false,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-card",
              { staticClass: "card-text shadow-sm bg-white rounded" },
              [
                _c("b-card-title", { staticClass: "card-title" }, [
                  _vm._v(" API Tokens "),
                ]),
                _c(
                  "div",
                  [
                    _c("b-table", {
                      attrs: {
                        fields: _vm.tokenFields,
                        items: _vm.filteredTokens,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "cell(detail)",
                          fn: function (row) {
                            return [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { size: "sm" },
                                  on: { click: row.toggleDetails },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.detailsShowing
                                          ? "Esconder"
                                          : "Mostrar"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "row-details",
                          fn: function (row) {
                            return [
                              _c(
                                "b-card",
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "text-sm-right",
                                          attrs: { sm: "3" },
                                        },
                                        [_c("b", [_vm._v("Token:")])]
                                      ),
                                      _c("b-col", [
                                        _vm._v(_vm._s(row.item.key)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(key)",
                          fn: function (row) {
                            return [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  attrs: { title: _vm.tooltipCopyToken },
                                },
                                [
                                  _c("img", {
                                    staticClass: "item-token-table",
                                    attrs: { src: _vm.copyTokenIcon },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyToken(
                                          row.item,
                                          row.index,
                                          $event.target
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(remove)",
                          fn: function (row) {
                            return [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  attrs: { title: _vm.tooltipRemoveToken },
                                },
                                [
                                  _c("img", {
                                    staticClass: "item-token-table",
                                    attrs: { src: _vm.deleteTokenIcon },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteToken(
                                          row.item,
                                          row.index,
                                          $event.target
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c(
                      "b-button",
                      {
                        staticClass: "corporate-button",
                        on: {
                          click: function ($event) {
                            return _vm.onCreateTokenClick()
                          },
                        },
                      },
                      [_vm._v(" Criar token ")]
                    ),
                    _c(
                      "b-link",
                      {
                        staticClass: "howTo",
                        on: {
                          click: function ($event) {
                            return _vm.onHowToClick()
                          },
                        },
                      },
                      [_vm._v("Como usar")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-card",
              { staticClass: "card-text shadow-sm bg-white rounded" },
              [
                _c("img", {
                  staticClass: "item-table",
                  attrs: { src: _vm.deleteIcon },
                  on: {
                    click: function ($event) {
                      return _vm.onRemoveAccount()
                    },
                  },
                }),
                _c(
                  "b-link",
                  {
                    staticClass: "delete-link",
                    on: {
                      click: function ($event) {
                        return _vm.onRemoveAccount()
                      },
                    },
                  },
                  [_vm._v("Remover minha conta")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-modal",
          {
            ref: "modalToken",
            attrs: {
              id: "modal-prevent-closing",
              "ok-title": _vm.okTitle,
              "cancel-title": "Cancelar",
              "cancel-variant": "transparent",
              title: _vm.modalTokenTitle,
              size: "lg",
            },
            on: { hidden: _vm.resetTokenModal, ok: _vm.handleModalTokenOk },
          },
          [
            _c(
              "b-form",
              {
                on: {
                  submit: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.handleTokenSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            staticStyle: { "font-weight": "600" },
                            attrs: {
                              label: "Nome",
                              "label-for": "name-input",
                              state: _vm.nameTokenState,
                              "invalid-feedback": "Nome é necesspario",
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "border border-dark",
                              attrs: {
                                id: "name-input",
                                state: _vm.nameTokenState,
                                placeholder: "Digite o nome...",
                                autofocus: "",
                              },
                              model: {
                                value: _vm.token.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.token, "name", $$v)
                                },
                                expression: "token.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-modal",
          {
            ref: "modal",
            attrs: {
              id: "modal-prevent-closing",
              "ok-title": _vm.okTitle,
              "cancel-title": "Cancelar",
              "cancel-variant": "transparent",
              title: _vm.modalTitle,
              size: "lg",
            },
            on: { hidden: _vm.resetModal, ok: _vm.handleModalOk },
          },
          [
            _c(
              "b-form",
              {
                on: {
                  submit: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.handleSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-card",
                          {
                            attrs: {
                              "no-body": "",
                              disabled: _vm.disableAdminChoose,
                            },
                          },
                          [
                            _c("b-card-header", { style: _vm.headerAllChose }, [
                              _vm._v(" Remover conta"),
                            ]),
                            _c(
                              "b-card-body",
                              [
                                _c("b-card-text", [
                                  _vm._v(
                                    "Deseja remover a conta e todos os seus usuários ?"
                                  ),
                                ]),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: { switch: "" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.allAccount()
                                      },
                                    },
                                    model: {
                                      value: _vm.removeAllCheckBox,
                                      callback: function ($$v) {
                                        _vm.removeAllCheckBox = $$v
                                      },
                                      expression: "removeAllCheckBox",
                                    },
                                  },
                                  [_vm._v("Excluir a conta e seus usuários")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        staticStyle: {
                          "max-width": "10px",
                          "text-align": "center",
                          "align-items": "center",
                          "justify-content": "center",
                          "align-self": "center",
                        },
                      },
                      [_c("p", [_vm._v("OU")])]
                    ),
                    _c(
                      "b-col",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "b-card",
                          {
                            ref: "cardAdminChoose",
                            attrs: {
                              disabled: _vm.disableAllChoose,
                              "no-body": "",
                            },
                          },
                          [
                            _c(
                              "b-card-header",
                              { style: _vm.headerAdminChoose },
                              [_vm._v("Selecionar Admin")]
                            ),
                            _c("b-table", {
                              staticClass: "user-table",
                              staticStyle: {
                                "{ max-height": "300px",
                                height: "300px",
                              },
                              attrs: {
                                fields: _vm.usersField,
                                items: _vm.filteredUsersGroups,
                                "sticky-header": "",
                                "thead-class": "d-none",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "cell(action)",
                                  fn: function (row) {
                                    return [
                                      _c("b-form-radio", {
                                        attrs: {
                                          disabled: _vm.radioGroupDisabled,
                                          switch: "",
                                          size: "md",
                                          name: "check-button",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.userAdmin(
                                              row.item,
                                              row.index,
                                              $event
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.userAdminSelected,
                                          callback: function ($$v) {
                                            _vm.userAdminSelected = $$v
                                          },
                                          expression: "userAdminSelected",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-modal",
          {
            ref: "howToModal",
            staticClass: "body",
            attrs: {
              "cancel-variant": "transparent",
              "cancel-title": "Cancelar",
            },
          },
          [
            _c("h4", { staticClass: "ml-2" }, [_vm._v("Requests para API")]),
            _c("b-form-group", { staticClass: "tab" }, [
              _c("ol", { attrs: { type: "1" } }, [
                _c("li", [
                  _c("p", { staticClass: "aboutAccuracy mt-4" }, [
                    _vm._v("Ao solicitar uma nova transcrição de áudio"),
                  ]),
                  _c("p", [
                    _c("code", [
                      _vm._v(
                        " POST https://services.retextoar.com.br/transcription"
                      ),
                    ]),
                  ]),
                  _c("pre", [
                    _vm._v("  body:\n"),
                    _c("code", [
                      _vm._v(
                        "\n  {\n    multichannel: boolean,\n    name: string\n  }\n"
                      ),
                    ]),
                    _vm._v("\n  header:\n  "),
                    _c("code", [
                      _vm._v("\n  {\n    Cookie: access_token: token\n  }\n  "),
                    ]),
                    _vm._v("\n"),
                  ]),
                  _c("p", [
                    _vm._v("Se o envio for correto irá retornar um "),
                    _c("b", [_vm._v("'id'")]),
                    _vm._v(" para utilizar posteriormente"),
                  ]),
                ]),
                _c("li", [
                  _c("p", { staticClass: "aboutAccuracy mt-4" }, [
                    _vm._v("Para resgatar a transcrição, será utilizado o "),
                    _c("b", [_vm._v("'id'")]),
                    _vm._v(" recebido"),
                  ]),
                  _c("p", [
                    _c("code", [
                      _vm._v(
                        " GET https://services.retextoar.com.br/transcription/:id"
                      ),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v("Sendo o retorno o json com o conteúdo do áudio"),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "background-section-title" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h2", [_vm._v("Perfil")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }